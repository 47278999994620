import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {
  protected defaultController = `${environment.baseUrl}/api/`;
  protected slug = 'paymentgateway';
  constructor(public http: HttpClient, private snackBar: MatSnackBar) {
  }

  public getPaymentGateways(): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}`);
  }
}
