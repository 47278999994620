import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WatcherService {
  subject: Subject<any> = new Subject<any>();
  constructor(private router: Router) {
  }

  public change(data) {
    this.subject.next(data);
  }

  public get() {
    return this.subject.asObservable();
  }

  public logout() {
    localStorage.clear();
    this.change({ jwt: null });
    this.router.navigate([`/login`]);
  }
}
