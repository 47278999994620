import { JwtHelperService } from '@auth0/angular-jwt';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export function isEmptyOrNull(value) {
    return value === undefined || value === null || value === '';
}

export function getJwtToken() {
    return localStorage.getItem('jwt');
}

export function isLoggedIn() {
    const helper = new JwtHelperService();
    if (!isEmptyOrNull(getJwtToken())) {
        return !helper.isTokenExpired(getJwtToken());
    }
    return false;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export function isAdmin(): boolean {
    if (!isLoggedIn()) {
        return false;
    }
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(getJwtToken());
    if (isEmptyOrNull(decodedToken.groupId)) {
        return false;
    }
    return decodedToken.groupId > 0;
}


export function openLiveChat() {
    // const w: any = window;
    // const LiveChatWidget = w.LiveChatWidget;
    // LiveChatWidget.call('maximize')
}

export function sendLiveChatMessage(message: string) {
    openLiveChat();
}

export function openInNewTab(url) {
    window.location.href = url;
}